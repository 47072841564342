import Typed from 'typed.js';

const typeAnimation = function () {
	var typed = new Typed('.auto-type', {
		strings: [
			'promotional',
			'social-media',
			'event',
			'real-estate',
			'corporate',
		],
		typeSpeed: 100,
		backSpeed: 50,
		loop: true,
	});
};

export default typeAnimation;
