const btnSubmit = document.querySelector('form button');
const form = document.querySelector('form');

function formReset() {
	btnSubmit.addEventListener('click', () => {
		form.reset();
	});
}

export default formReset;
