// // Get the button:
let btnTop = document.querySelector('.btn-top');
const body = document.body;

// // When the user scrolls down 20px from the top of the document, show the button
// window.onscroll = function () {
// 	scrollFunction();
// };

const scrollFunction = function () {
	if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
		btnTop.style.display = 'block';
	} else {
		btnTop.style.display = 'none';
	}
};

const topFunction = function () {
	if (btnTop) {
		btnTop.addEventListener('click', () => {
			document.body.scrollTop = 0; //* For Safari
			document.documentElement.scrollTop = 0; //* For Chrome, Firefox, IE and Opera
		});
	}
};

export { topFunction, scrollFunction };
