// - IMPORTING
import "./styles/main.scss"; //_ STYLES
import barba from "@barba/core"; //_ BARBA JS

import {
  clickLinkCloseNav,
  dropDownMenuFunction,
  navSlide,
} from "./scripts/animations/nav/burgerMenu"; //_NAV

// -IMPORTING ANIMATIONS
import {
  animationLeave,
  animationEnter,
} from "./scripts/animations/barbaPageTransitions/pageFade";

import preventReloadClick from "./scripts/animations/barbaPageTransitions/preventReload";
import typeAnimation from "./scripts/animations/heroType";
import { topFunction } from "./scripts/animations/nav/topButton";
import formReset from "./scripts/functionality/handleForm";

// _GLOBAL
clickLinkCloseNav();
navSlide();
dropDownMenuFunction();

// _Top Button
topFunction();
// _FORM
formReset();

// -HOMEPAGE ANIMATIONS
const navBar = document.querySelector("nav");

// -BARBA JS
barba.hooks.beforeLeave(() => {
  window.scrollTo(0, 0);
});

preventReloadClick();

//_Barba Init
barba.init({
  transitions: [
    {
      // -FADE IN & OUT
      name: "fadeIn",
      leave: ({ current }) => animationLeave(current.container),
      enter({ next }) {
        animationEnter(next.container);
      },
    },
    {
      // -HOMEPAGE LOAD ANIMATION ON FIRST LOAD
      name: "homePageLoad",
      to: {
        namespace: ["homepage"],
      },
      once() {
        typeAnimation();
      },
      leave: ({ current }) => animationLeave(current.container),
      enter({ next }) {
        animationEnter(next.container);
        typeAnimation();
      },
    },
  ],
});
