import gsap from 'gsap';

const animationEnter = function (container) {
	return gsap.from(container, {
		autoAlpha: 0,
		duration: 0.75,
		clearProps: 'all',
		ease: 'none',
	});
};

const animationLeave = function (container) {
	return gsap.to(container, {
		autoAlpha: 0,
		duration: 0.75,
		clearProps: 'all',
		ease: 'none',
	});
};

export { animationEnter, animationLeave };
