var links = document.querySelectorAll('a[href]');
var cbk = function (e) {
	if (e.currentTarget.href === window.location.href) {
		e.preventDefault();
		e.stopPropagation();
	}
};

const preventReloadClick = function () {
	for (var i = 0; i < links.length; i++) {
		links[i].addEventListener('click', cbk);
	}
};

export default preventReloadClick;
