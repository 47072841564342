import gsap from 'gsap';

const burger = document.querySelector('.burger');
const nav = document.querySelector('.nav-links');
const navLinks = document.querySelectorAll('.nav-links a');
const body = document.querySelector('body');
const projectsMenu = document.querySelector('.projects-menu');
const projectsDrop = document.querySelector('.projects-dropdown');

// _BURGER MENU
const navSlide = () => {
	// Toggle NAV
	burger.addEventListener('click', () => {
		nav.classList.toggle('nav-active');
		burger.classList.toggle('toggle');

		nav.classList.contains('nav-active')
			? (body.style.overflow = 'hidden')
			: (body.style.overflow = 'visible');
	});
};

const clickLinkCloseNav = function () {
	navLinks.forEach((navLink) => {
		navLink.addEventListener('click', () => {
			nav.classList.remove('nav-active');
			body.style.overflow = 'visible';
			burger.classList.remove('toggle');
		});
	});
};

// -DROPDOWN MENU

const removeDropDown = function () {
	projectsDrop.classList.remove('projects-menu-active');
};

const addDropDown = function () {
	projectsDrop.classList.toggle('projects-menu-active');
};

const dropDownMenuFunction = function () {
	projectsMenu.addEventListener('click', addDropDown);
	projectsDrop.addEventListener('mouseleave', removeDropDown);
};

export { navSlide, dropDownMenuFunction, clickLinkCloseNav };
